const api = require("./api")

const getArticles = async () => {
    return await api.getAllResource("articles");
}

const getAdminArticles = async () => {
    return await api.getAllResource("admin/articles");
}


const getArticle = async (id) => {
    return await api.getResource("articles", id);
}

const createArticle = async (body) => {
    return await api.createResource("articles", body);
}

const updateArticle = async (id, body) => {
    return await api.updateResource("articles", id, body);
}

const deleteArticle = async (id) => {
    return await api.deleteResource("articles", id)
}

const checkSlug = async (slug) => {
    return await api.resourceCustomFunction("POST", "articles", "check-slug", {slug})
}

const getArticleBySlug = async (slug) => {
    return await api.getResource("articles/slug", slug);
}


module.exports = {
    getArticles,
    getArticle,
    createArticle,
    updateArticle,
    deleteArticle,
    checkSlug,
    getArticleBySlug,
    getAdminArticles
}